export const mapping = {
	'Anemone' : 'Quicksand',
	'Coral' : 'PACING',
	'Crab' : 'La Croix',
	'Shark' : 'Drop',
	'Dolphin' : 'MAINTAINING',
	'Seahorse' : 'Activate',
	'Pufferfish' : 'Type Meltdown',
	'Clownfish' : 'Sidestep',
	'Shell' : 'Deep End',
	'Turtle' : 'Sea Grass',
	'Jellyfish' : 'Pulse',
}

export const tracklist = [
	'Deep End',
	'Activate',
	'Sidestep',
	'Sea Grass',
	'La Croix',
	'Type Meltdown',
	'Pulse',
	'MAINTAINING',
	'PACING',
	'Drop',
	'Quicksand',
]